import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class Monopoly extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div id="project-monopoly" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <img id="logo1" src={require('assets/images/projects/monopoly/Header.png')} alt="Monopoly Header" /> 
        </div>

        <div className="services projectsectionhidden">
          <div className="indicator top_0">{t('Services')}</div>
          <div class="service">
            <h4>{t('Strategic Direction')}</h4>
            <h4 class="">{t('Creative Direction')}</h4>
            <h4>{t('Graphic Design')}</h4>
            <h4>{t('Video Production')}</h4>
            <h4 class="grid-span-2">{t('Web Development & Design')}</h4>
            <h4 class="">{t('Augmented Reality')}</h4>
            <h4 class="grid-span-2">{t('Sponsorship Campaign Development')}</h4>
            <h4 class="">{t('Management and Sales')}</h4>
          </div>
        </div>

        <div className="description">
          <img src={require('assets/images/projects/monopoly/proactive.png')} alt="Monopoly Logo" />
          <p dangerouslySetInnerHTML={{__html: i18n.language === 'en' ? 
            `Partnering with Hasbro Inc, and in collaboration with ProAction Cops & Kids, our team was tasked with creating the official Monopoly - Toronto Edition. The goal while creating the game was to bring together iconic brands, venues, landmarks and create a unique board game that was exclusively the ‘6ix’.
            <br />
            Each property on the board was a sold to a Toronto-based sponsor in exchange for brand recognition and a percentage of game sales donated to a charitable organization of their choice.
            <br />
            Working alongside these key stakeholders, our team created unique design assets for each sponsored property, deed card, as well as community chest and chance cards with our very own version of Mr. Monopoly - Toronto Edition!
            `: 
            `
            En partenariat avec Hasbro Inc., et en collaboration avec ProAction Cops & Kids, notre équipe était chargée de créer un jeu officiel de Monopoly – Édition Toronto. L’objectif était de réunir des marques commerciales, des lieux et des sites emblématiques et de créer un jeu de société exclusif à la région de Toronto. 
            <br />
            Chaque propriété de la planchette de jeu a été vendue à un commanditaire de Toronto. En échange, la marque de commerce de l’acquéreur a été affichée sur la planchette et un pourcentage des ventes du jeu est versé à une organisation caritative de son choix.
            <br />
            En collaboration avec les principaux intervenants, notre équipe a créé des biens uniques en matière de conception pour chaque propriété commanditée ainsi que des cartes de titres de propriétés, et des cartes « Caisse commune » et « Chance » avec notre propre version de Monsieur Monopoly – Édition de Toronto!
            `}}>
          </p>
        </div>

        <div className="photo4 projectsectionhidden">
          <img src={require('assets/images/projects/monopoly/Monopoly_C_HC.png')} alt="project label" />
        </div>

        <div className="photo4 projectsectionhidden">
          <div className="indicator top_0">{t('Board design')}</div>
          <img src={require('assets/images/projects/monopoly/Monopoly_D_HC.png')} alt="project label" />
        </div>

        <div className="photo4 projectsectionhidden">
          <div className="indicator">{t('Card design')}</div>
          <img src={require('assets/images/projects/monopoly/Monopoly_E_HC.png')} alt="project label" />
        </div>

        <div className="photo4 projectsectionhidden">
          <div className="indicator">{t('Sponsorship campaign')}</div>
          <img src={require('assets/images/projects/monopoly/Monopoly_F_HC.png')} alt="project label" />
        </div>

        <div className="productvideo projectsectionhidden">
          <div className="indicator">{t('Commercial')}</div>
          <video width="100%" height="auto" controls={true} poster={require('assets/images/projects/monopoly/monopoly-video-cover.png')}>
            <source src={require('assets/videos/monopoly-commercial.mp4')} type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

      </div>
    )
  }
}

export default withTranslation()(Monopoly);
