import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class ParkLand extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div id="project-parkland" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner">
          <img class="img_fluid" src={require('assets/images/projects/parkland/Parkland_A_HC.png')} />
          <h1 dangerouslySetInnerHTML={{__html: t('2021 Parkland Corporation Investor Day')}}></h1>
        </div>

        <div className="services projectsectionhidden">
          <div className="indicator top_0">{t('Services')}</div>
          <div class="service">
            <h4>{t('Strategic Direction')}</h4>
            <h4 class="">{t('Event Planning and Execution')}</h4>
            <h4>{t('Staging')}</h4>
            <h4>{t('Speaker Support')}</h4>
            <h4 class="">{t('Virtual Production')}</h4>
            <h4 class="">{t('Web Development & Design')}</h4>
            <h4 class="">{t('Animation & Motion Design')}</h4>
            <h4>{t('Photography')}</h4>
          </div>
        </div>

        <div className="description">
          <img src={require('assets/images/logos/clients/Parkland2021.png')} alt="Parkland Logo" />
          <p dangerouslySetInnerHTML={{__html: i18n.language === 'en' ? 
            `In November of 2021, HC was challenged by the team at Parkland to turn their annual Investors' meeting into a hybrid offering for both live and remote attendees. Over 100 attendees joined in person at the Fairmont Royal York Hotel in Toronto, and over 350 joined remotely as Parkland’s leadership team shared their plans for 
            <br/>long-term growth and energy transition strategy, capital allocation and their financial outlook. The remote attendees were hosted on HUMANCONTACT’s Merjj Portal, and all attendees were able to revisit the presentation after it had concluded.` 
            : 
            `En novembre 2021, l'équipe de Parkland a mis HC au défi de transformer sa réunion annuelle 
            des investisseurs en un 
            <br />événement hybride pour les participants en présentiel et à distance. 
            Plus de 100 participants ont assisté à l’événement en personne à l'hôtel 
            Fairmont Royal York à Toronto et plus de 350 y ont assisté à distance, 
            alors que l'équipe de direction de Parkland a partagé ses plans de croissance à 
            long terme et sa stratégie de transition énergétique, l'allocation du capital et 
            ses perspectives financières. La participation à distance a été rendue possible grâce à 
            la plateforme Merjj de HUMANCONTACT, et tous les participants 
            <br />ont pu avoir accès à l’enregistrement de la 
            <br />présentation une fois celle-ci terminée.`}}>
          </p>
        </div>

        {/* <div className="photo4 projectsectionhidden">
          <img src={require('assets/images/projects/parkland/Parkland_C_HC.png')} alt="project label" />
        </div> */}

        <div className="photo4 projectsectionhidden">
          <div className="indicator">{t('Merjj Portal')}</div>
          <img src={require('assets/images/projects/parkland/Parkland_D_HC.png')} alt="project label" />
        </div>

        <div className="productvideo projectsectionhidden bottom_50">
          <div className="indicator top_30">{t('Virtual Stream')}</div>
          <video width="90%" height="auto" controls={true} poster={require('assets/images/projects/parkland/Parkland-Cover.png')}>
            <source src={require('assets/videos/PKL-Video.mp4')} type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

        <div className="photo4 projectsectionhidden bottom_50">
          <img class="mx" src={require('assets/images/projects/parkland/Parkland_2.png')} alt="project label" />
        </div>

        <div className="photo4 projectsectionhidden bottom_90">
          <img class="mx" src={require('assets/images/projects/parkland/Parkland_3.png')} alt="project label" />
        </div>

        <div className="photo4 projectsectionhidden">
          <div className="indicator top_50">{t('In Person Components')}</div>
          <img src={require('assets/images/projects/parkland/Parkland_F_HC.png')} alt="project label" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(ParkLand);
