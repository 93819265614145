import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { mapStyle } from 'components/MapStyle.js';

// Shapes API added from index.html header

const Marker = () => <div id="hc-building"></div>;
const humancontactCoordinates = {
  lat: 43.638920,
  lng: -79.426570
}

class GoogleMap extends Component {

  static defaultProps = {
    center: humancontactCoordinates,
    zoom: 17
  };

  render() {

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAtP6PgVxkHzYF8-rtTd5BBVoywHp62QtE' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={{ styles: mapStyle }}
          onGoogleApiLoaded={
            ({map, maps}) => {

              maps.event.addListenerOnce(map, 'idle', () => {
                this.props.onMapLoad()
              })

              window.hc.map.map = map
              window.hc.map.setCenter = (city) => {
                switch(city) {
                  case 'toronto': map.setCenter(new google.maps.LatLng(43.63856980067551, -79.42658859693495), 19);
                  break;

                  case 'ottawa': map.setCenter(new google.maps.LatLng(45.39872193625854, -75.73627664418113), 20);
                  break;

                  default: console.warn(`Parameter must be 'toronto' or 'ottawa'. Received ${city}.`)
                }
              }

              // https://www.doogal.co.uk/polylines.php
              // -- paths / polygon generator
              const shapes = [];
              const { google } = window;
              const pathToronto = [
                new google.maps.LatLng(43.63886585459397, -79.42710765377672),
                new google.maps.LatLng(43.63906384903054, -79.42615815178544),
                new google.maps.LatLng(43.63881926757291, -79.42606159226091),
                new google.maps.LatLng(43.63862363541845, -79.42700761728497)];
              const pathOttawa = [
                new google.maps.LatLng(45.39860565595824, -75.73604642662242),
                new google.maps.LatLng(45.39855613660779, -75.73627103019528),
                new google.maps.LatLng(45.39869439487877, -75.7363653056512),
                new google.maps.LatLng(45.39874109642018, -75.73613800939195),
                new google.maps.LatLng(45.39860470691747, -75.73604555699997),
                new google.maps.LatLng(45.39858, -75.73616)];
              const polyline = new google.maps.Polygon({ path:pathToronto, strokeColor: 'transparent', fillColor: '#e8bf2f', fillOpacity: 0.4, strokeWidth: 0 });
              const polylineOttawa = new google.maps.Polygon({ path:pathOttawa, strokeColor: 'transparent', fillColor: '#e8bf2f', fillOpacity: 0.4, strokeWidth: 0 });
              polyline.setMap(map);
              polylineOttawa.setMap(map);
              window.hc.map.setCenter('toronto')
              shapes.push(polyline);
              shapes.push(polylineOttawa);

              return shapes

            }

            }
          >
          <Marker
            lat={humancontactCoordinates.lat}
            lng={humancontactCoordinates.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;
