import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

class Accessibility extends React.Component {
  componentDidMount() {
    window.hc.screen.updateIndex(0)
  }

  render() {
      const { i18n } = this.props;
      if (i18n.language === 'en') {
        return (
            <div className={"text-page" + (window.hc.device.isMobile ? ' ismobile' : '')}>
                <Helmet>
                    <title>HUMANCONTACT | Accessibility</title>
                    <meta name="HUMANCONTACT" content="Accessibility" />
                </Helmet>
                <h1>Accessibility</h1>
                <div className="content">
                    <p><strong>1. General</strong></p>
                    <p>
                        HC strives to provide our services in a way that respects the dignity and independence
                        of people with disabilities. We are committed to giving people with disabilities the same
                        opportunity to access our business and services in the same place and in a similar way
                        as others to fully comply with the standards developed under the Accessibility for
                        Ontarians with Disabilities Act (“AODA”).
                    </p>
                    <p>
                        HC will use reasonable efforts to ensure that its policies, practices, and procedures are
                        consistent with the core principles of independence, dignity, integration and equal
                        opportunity. Our plan and policy regarding AODA compliance is reviewed every two
                        years and will be updated to ensure the most accurate and up-to date considerations
                        to address current and future accessibility needs.
                        Latest Revision: November 2023
                        Next Review Period: November 2025
                    </p>
                    <p className="section"><strong>2. Accommodations</strong></p>
                    <p>Some of our previous and existing accommodations to address accessibility and disability include:</p>
                    <ol type="a">
                        <li>
                            <p>
                                Having multiple methods of access into the main office (elevator and wheelchair – friendly devices)
                            </p>
                        </li>
                        <li>
                            <p>
                                Allowing entry to service animals and support persons
                            </p>
                        </li>
                        <li>
                            <p>
                                Providing accessible formats and communications supports such as large-font and recorded audio.
                            </p>
                        </li>
                        <li>
                            <p>
                                Processes in place to request accommodations for assistive devices for everyday tasks.
                            </p>
                        </li>
                        <li>
                            <p>
                                An ‘HC Helpline’ to anonymously or directly notify of any accessibility needs or address questions.
                            </p>
                        </li>
                    </ol>    
                    <p> 
                        We encourage all staff and visitors to notify any needs for accommodation so we may 
                        address these needs in an appropriate and timely manner. For any disability and
                        accessibility needs, please email or call:
                    </p>
                    <p><strong>Mary Cressos</strong></p>
                    <p><a href="mailto:Mary.cressos@humancontact.com">Mary.cressos@humancontact.com</a> OR</p>
                    <p><a href="mailto:admin@humancontact.com">admin@humancontact.com</a></p>
                    <p>416.361.0113 x 333</p>

                    <p className="section"><strong>3. Communications</strong></p>
                    <p>
                        HC will communicate with its clients, suppliers or associates in a manner that
                        accommodates any disability they may have.
                    </p>
                    <p>
                        Invoices will be provided in alternative format upon request. HC will answer any
                        questions clients, suppliers or associates may have about the content of the invoice in
                        person, by telephone or email.
                    </p>
                    <p className="section"><strong>4. Assistive Devices and Service Animals</strong></p>
                    <p>
                        HC clients, suppliers or associates with disabilities may use their personal assistive
                        devices on HC’s premises and use any other measures HC offers (assistive devices,
                        services, etc.) to help them access HC’s site and services.
                    </p>
                    <p>
                        HC clients, suppliers or associates with disabilities may be accompanied by their
                        service animal in those areas of HC’s premises that are open to the public unless the
                        animal is excluded by another law. If the service animal is excluded by another law, HC
                        will have an alternative method to provide services. Every attempt will be made to
                        ensure that any offsite event venues are compliant with HC’s commitment regarding
                        service animals.
                    </p>
                    <p className="section"><strong>5. Support Persons</strong></p>
                    <p>
                        Associates/suppliers/clients with disabilities may be accompanied by a support
                        person. Any person with a disability who is accompanied by a support person will be
                        allowed to enter HC’s premises or work sites with their support person.
                    </p>
                    <p>
                        A support person can be a personal support worker, a volunteer, a family member, or a
                        friend. A support person might help the client/associate with a variety of things, from
                        communicating to helping with mobility, personal care, or medical needs.
                    </p>
                    <p>
                        At no time will a person with a disability who is accompanied by a support person be
                        prevented from having access to his or her support person while on our premises or
                        HC work sites.
                    </p>
                    <p className="section"><strong>6. Disruption Notices</strong></p>
                    <p>
                        HC will provide notice when facilities or services on which associates/clients/suppliers
                        with disabilities rely are temporarily disrupted.
                    </p>
                    <p>
                        The notice will be posted in a conspicuous place on HC’s premises, on our website, or
                        by another reasonable method. The notice will include information about the reason for
                        the disruption, its anticipated duration, and a description of alternative facilities or
                        services, if available.
                    </p>
                    <p className="section"><strong>7. Feedback Process</strong></p>
                    <p>
                        HC has established an ‘HC Helpline’ available on Club404 for people to give feedback
                        on how HC provides services to clients/associates/suppliers with disabilities.
                        Alternatively, an employee can personally speak to any member of senior
                        management, HR, the Joint Health and Safety Committee, or the President directly to
                        address any concerns regarding accessibility, which will be addressed as soon as
                        possible and with high consideration to address the concerns reasonably and
                        appropriately.
                    </p>
                    <p className="section"><strong>8. Questions</strong></p>
                    <p>
                        This policy exists to achieve service excellence to HC staff, clients, suppliers or
                        associates with disabilities. Questions about this policy can be directed to the
                        management team and/or the President.
                    </p>
                    <p>
                        Although the following points are not specifically covered under the AODA legislation,
                        HC strongly encourages all of our staff, volunteers, contractors and other third parties
                        who represent our organization to adhere to the following practices:
                    </p>
                    <ol type="a">
                        <li>
                            <p>
                                Always communicate in the most appropriate manner possible, considering the
                                individual’s disability. Sometimes the best approach is to ask the
                                client/associate/ supplier with a disability how you can best communicate with
                                them.
                            </p>
                        </li>
                        <li>
                            <p>
                                Don’t touch assistive devices, such as canes or wheelchairs, without first
                                obtaining permission from the individual. Never move them out of the person’s
                                reach.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ensure that you never leave a client/associate/supplier with a disability in an
                                awkward, dangerous, or undignified position, such as in the path of opening
                                doors, or facing a wall.
                            </p>
                        </li>
                        <li>
                            <p>
                                In the case where a client/associate/supplier is with a disability is accompanied
                                by their support animal, avoid making assumptions about the animal. Service
                                animals are often easily identified through the use of coats and/or harnesses. If
                                you’re unsure if the animal is a pet or a service animal, ask the
                                client/associate/supplier. Avoid petting or addressing service animals, as they
                                are working and have to pay attention at all times.
                            </p>
                        </li>
                        <li>
                            <p>
                                In the case where a client/associate/supplier with a disability is accompanied by
                                a support person, ensure that you always communicate directly with the
                                client/associate/supplier, unless they ask you to speak with the support person.
                            </p>
                        </li>
                        <li>
                            <p>
                                Always approach the client/associate/supplier with a disability in a manner and
                                from a direction that best allows them to become aware of your presence
                                without being startled.
                            </p>
                        </li>
                        <li>
                            <p>
                                Let the client/associate/supplier with a disability know about accessible features
                                in the immediate environment that are appropriate to their needs, e.g., TTY,
                                accessible washroom facilities, etc.
                            </p>
                        </li>
                        <li>
                            <p>
                                Always treat a client/associate/supplier with a disability with the same respect,
                                consideration, and patience that you have for everyone else. Avoid interrupting
                                and/or finishing their sentences. Never assume that someone with a particular
                                disability, such as speech impairment, also has another disability.
                            </p>
                        </li>
                        <li>
                            <p>
                                Never make assumptions about what the client/associate/supplier with a
                                disability is capable of doing. If in doubt ask if, and how, you can be of
                                assistance to them. They will likely appreciate your attention and consideration.
                                Remember that our clients/associates are our best source of information about
                                their needs
                            </p>
                        </li>
                    </ol>   
                </div>     
            </div>
          )
      } else {
        return (
            <div className={"text-page" + (window.hc.device.isMobile ? ' ismobile' : '')}>
                <Helmet>
                    <title>HUMANCONTACT | Modalités et conditions</title>
                    <meta name="HUMANCONTACT" content="Modalités et conditions" />
                </Helmet>
                <h1>Accessibilité</h1>
                <div className="content">
                    <p><strong>1. Généralités</strong></p>
                    <p>
                        HC s'efforce de fournir ses services d'une manière qui respecte la dignité et l'indépendance 
                        des personnes handicapées. Nous nous engageons à donner aux personnes handicapées la même 
                        possibilité d'accéder à nos activités et à nos services au même endroit et de la même 
                        manière que toute autre personne, afin de respecter pleinement les normes élaborées dans 
                        le cadre de la Loi sur l'accessibilité pour les personnes handicapées de l'Ontario (« LAPHO »).
                    </p>
                    <p>
                        HC déploiera des efforts raisonnables pour s'assurer que ses politiques, pratiques et procédures 
                        sont conformes aux principes fondamentaux d'indépendance, de dignité, d'intégration et d'égalité 
                        des chances. Notre plan et notre politique concernant la conformité à la LAPHO sont examinés tous 
                        les deux ans et seront mis à jour afin de garantir les considérations les plus précises et les 
                        plus actuelles pour répondre aux besoins actuels et futurs en matière d'accessibilité.
                        Dernière mise à jour : novembre 2023
                        Prochaine mise à jour : novembre 2025
                    </p>
                    <p className="section"><strong>2. Mesures d’adaptation</strong></p>
                    <p>Voici quelques-unes des mesures d’adaptation antérieures et actuelles prises pour aborder les enjeux d’accessibilité et de handicap :</p>
                    <ol type="a">
                        <li>
                            <p>
                                Prévoir plusieurs façons d’accéder aux locaux du bureau principal (ascenseurs et dispositifs adaptés aux fauteuils roulants).
                            </p>
                        </li>
                        <li>
                            <p>
                                Autoriser l’accès aux animaux d’assistance et aux personnes de soutien. 
                            </p>
                        </li>
                        <li>
                            <p>
                                Offrir des formats et supports de communication accessibles tels que les gros caractères et les enregistrements audio.
                            </p>
                        </li>
                        <li>
                            <p>
                                Mise en place de procédures pour les demandes d’adaptation d’appareils d’assistance pour les tâches quotidiennes. 
                            </p>
                        </li>
                        <li>
                            <p>
                                Mise en place d’une « Ligne secours HC » pour signaler (de façon anonyme ou directement) un besoin en matière d’accessibilité ou pour poser des questions.
                            </p>
                        </li>
                    </ol>    
                    <p> 
                        Nous encourageons l’ensemble du personnel ainsi que les visiteurs à nous faire part de tout 
                        besoin d’adaptation afin que nous puissions y répondre de manière appropriée et opportune. 
                        Pour tout besoin lié à un handicap ou à l’accessibilité, prière de communiquer avec :
                    </p>
                    <p><strong>Mary Cressos</strong></p>
                    <p><a href="mailto:Mary.cressos@humancontact.com">Mary.cressos@humancontact.com</a> OU</p>
                    <p><a href="mailto:admin@humancontact.com">admin@humancontact.com</a></p>
                    <p>416-361-0113, poste 333</p>

                    <p className="section"><strong>3. Communications</strong></p>
                    <p>
                        HC communiquera avec ses clients, fournisseurs et associés de manière à tenir 
                        compte de leur handicap, le cas échéant.
                    </p>
                    <p>
                        Les factures seront fournies dans un média substitut, sur demande. HC répondra en 
                        personne, par téléphone ou par courriel aux questions des clients, fournisseurs et 
                        associés au sujet des détails d’une facture. 
                    </p>
                    <p className="section"><strong>4. Appareils fonctionnels et animaux d’assistance</strong></p>
                    <p>
                        Les clients, fournisseurs et associés handicapés de HC peuvent se servir de leurs 
                        appareils fonctionnels personnels dans les locaux de HC et se servir de toute autre 
                        mesure offerte par HC (appareils et services d’assistance, etc.) pour les aider à 
                        accéder au site et aux services de HC. 
                    </p>
                    <p>
                        Les clients, fournisseurs et associés handicapés de HC peuvent être accompagnés de 
                        leur animal d’assistance dans les aires de HC ouvertes au public, à moins que 
                        l’animal soit exclu en vertu d’une autre loi. Si l’animal de service est exclu 
                        en vertu d’une autre loi, les services seront offerts par le biais d’une méthode 
                        alternative. Tout sera mis en œuvre pour s’assurer que les lieux d’un événement 
                        hors site sont conformes à l’engagement de HC concernant les animaux d’assistance.
                    </p>
                    <p className="section"><strong>5. Personnes de soutien</strong></p>
                    <p>
                        Les associés, fournisseurs et clients handicapés peuvent être accompagnés d’une 
                        personne de soutien. Toute personne handicapée accompagnée d’une personne de 
                        soutien sera autorisée à entrer dans les locaux ou les lieux de travail de HC 
                        en compagnie de sa personne de soutien.
                    </p>
                    <p>
                        La personne de soutien peut être un préposé aux services de soutien à la personne, 
                        un bénévole, un membre de la famille ou un ami. La personne de soutien peut aider 
                        le client ou l’associé de diverses façons, allant de la communication, à l’aide 
                        à la mobilité, aux soins personnels et aux besoins médicaux. 
                    </p>
                    <p>
                        En aucun temps une personne handicapée accompagnée d’une personne de soutien se verra 
                        refuser l’accès à sa personne de soutien dans les locaux ou les lieux de travail de HC. 
                    </p>
                    <p className="section"><strong>6. Avis de perturbation</strong></p>
                    <p>
                        HC s’assurera de signaler toute perturbation temporaire relatives aux installations 
                        ou aux services sur lesquels comptent les associés, clients et superviseurs handicapés. 
                    </p>
                    <p>
                        Un avis à cet effet sera affiché à un endroit bien en vue dans les locaux de HC, 
                        sur son site Web, ou par une autre méthode raisonnable. L’avis comprendra des 
                        renseignements quant aux raisons de la perturbation, sa durée prévue, et une 
                        description des installations ou services de rechange, s’ils sont disponibles. 
                    </p>
                    <p className="section"><strong>7. Processus de rétroaction</strong></p>
                    <p>
                        HC a mis en place une « Ligne d’assistance HC » dans le cadre du programme Club404. 
                        Cette ligne d’assistance est destinée à recueillir la rétroaction des employés en 
                        ce qui concerne la qualité des services offerts par HC aux clients, associés et 
                        fournisseurs handicapés. Un employé peut également s’adresser personnellement à 
                        un membre de la direction, aux ressources humaines, au comité mixte de santé et 
                        sécurité, ou directement au président pour leur faire part de ses préoccupations 
                        en matière d’accessibilité. Ces préoccupations seront traitées dans les meilleurs 
                        délais et avec la plus grande attention afin d’y répondre de manière raisonnable et 
                        appropriée. 
                    </p>
                    <p className="section"><strong>8. Questions</strong></p>
                    <p>
                        La présente politique a pour but d’atteindre l’excellence en matière de service aux 
                        employés, fournisseurs et associés handicapés de HC. Toute question relative à 
                        cette politique peut être adressée à l’équipe de gestion ou au président.
                    </p>
                    <p>
                        Bien que les points ci-dessous ne soient pas spécifiquement couverts par la Loi, HC 
                        encourage vivement l’ensemble de son personnel et de ses fournisseurs, ainsi que 
                        toute tierce partie qui la représente, à adhérer aux pratiques suivantes :
                    </p>
                    <ol type="a">
                        <li>
                            <p>
                                Communiquez toujours de la façon la plus appropriée compte tenu du handicap de 
                                la personne. Parfois, la meilleure approche est de demander au client, à l’associé 
                                ou au fournisseur handicapé quelle est la meilleure façon de communiquer avec lui.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ne touchez pas aux dispositifs d’assistance, comme les cannes ou les fauteuils 
                                roulants, sans avoir obtenu au préalable la permission de la personne. Ne 
                                les déplacez jamais hors de leur portée.
                            </p>
                        </li>
                        <li>
                            <p>
                                Assurez-vous de ne jamais laisser un client, un associé ou un fournisseur 
                                handicapé dans une position inconfortable, dangereuse ou indigne, par exemple 
                                sur le passage d’une porte qui s’ouvre ou face à un mur. 
                            </p>
                        </li>
                        <li>
                            <p>
                                Dans le cas où un client, un associé ou un fournisseur handicapé serait accompagné 
                                de son animal d’assistance, évitez de faire des suppositions sur l’animal. Bien 
                                souvent, les animaux d'assistance portent un gilet ou un harnais qui permet de 
                                les identifier facilement. Si vous ne savez pas s'il s'agit d'un animal 
                                d'assistance ou d'un simple animal de compagnie, demandez-le au client, 
                                à l'associé ou au fournisseur. Évitez de flatter un animal d'assistance ou de 
                                le distraire, car il travaille et doit être attentif en tout temps.
                            </p>
                        </li>
                        <li>
                            <p>
                                Dans le cas où un client, un associé ou un fournisseur handicapé serait accompagné 
                                d'une personne de soutien, assurez-vous de toujours communiquer directement avec 
                                le client, l'associé ou le fournisseur, sauf si celui-ci vous demande de vous 
                                adresser à la personne de soutien.
                            </p>
                        </li>
                        <li>
                            <p>
                                Abordez toujours un client, un associé ou un fournisseur handicapé d’une manière 
                                et d’un angle qui lui permettra facilement de prendre connaissance de votre 
                                présence sans être pris par surprise.
                            </p>
                        </li>
                        <li>
                            <p>
                                Assurez-vous de renseigner le client, l’associé ou le fournisseur handicapé sur 
                                les caractéristiques d’accessibilité adaptés à ses besoins (p. ex. un ATS, des 
                                toilettes accessibles, etc.) qui se trouvent dans l’environnement immédiat.
                            </p>
                        </li>
                        <li>
                            <p>
                                Traitez toujours un client, un associé ou un fournisseur handicapé avec le même 
                                respect, la même considération et la même patience que vous avez pour les autres. 
                                Évitez de l’interrompre ou de terminer ses phrases. Ne supposez jamais qu’une 
                                personne atteinte d’un handicap particulier, comme un trouble de la parole, 
                                est également atteinte d’un autre handicap.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ne faites jamais de suppositions sur ce que le client, l’associé ou le fournisseur 
                                handicapé est capable de faire. En cas de doute, demandez-leur si vous pouvez 
                                les aider et de quelle façon. Ils apprécieront probablement votre attention et 
                                votre considération. N’oubliez pas qu’ils sont notre meilleure source d’information 
                                quant à leurs propres besoins.
                            </p>
                        </li>
                    </ol>       
                </div> 
            </div>
          )
      }
  }
}

export default withTranslation()(Accessibility);