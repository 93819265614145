import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class VirtualBeauty extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;
    const french_version = i18n.language === 'fr' ? '-fr' : '';

    return (
      <div id="project-sdm" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <img id="logo1" src={require('assets/images/projects/sdm/1_ShowtimeHeader' + french_version + '.png')} alt="Showtime Header" /> 
        </div>

        <div className="services projectsectionhidden">
          <div className="indicator top_0">{t('Services')}</div>
          <div class="service">
            <h4>{t('Strategic Direction')}</h4>
            <h4>{t('Creative Direction')}</h4>
            <h4>{t('Graphic Design')}</h4>
            <h4>{t('Video Production')}</h4>
            <h4>{t('Virtual Production')}</h4>
            <h4>{t('Web Development & Design')}</h4>
            <h4>{t('Augmented Reality')}</h4>
            <h4 class="grid-span-2">{t('Sponsorship Campaign')}</h4>
            <h4>{t('Tradeshow Management')}</h4>
          </div>
        </div>

        <div className="description">
          <img src={require('assets/images/projects/sdm/description-logo' + french_version + '.png')} alt="SDM Logo" />
          <p>
            {i18n.language === 'en' ? `In 2021, Shoppers Drug Mart took its bi-annual Conference for Beauty Managers online! The conference, that was hosted on HUMANCONTACT's Merjj Portal, featured two days jam packed with engaging hosts, amazing speakers, thought provoking workshops, a cooking competition and a wildly successful virtual trade show. The traditional product bag provided to Beauty Managers was enhanced with an Augmented Reality
            scavenger hunt which provided great value to the Conference Sponsors.` : `En 2021, Shoppers Drug Mart a mis en ligne sa conférence semestrielle pour les gérants et gérantes des comptoirs de beauté! La conférence, hébergée sur le portail Merjj de HUMANCONTACT, s'est déroulée sur deux jours avec des animateurs passionnants, des conférenciers extraordinaires, des ateliers suscitant la réflexion et un salon virtuel qui a remporté un vif succès. Le traditionnel sac de produits remis aux participants et participantes a été enrichi d'une chasse au trésor en réalité augmentée qui a apporté une valeur ajoutée aux commanditaires de la conférence.`}
          </p>
        </div>

        <div className="photo4 projectsectionhidden">
          <div className="indicator">{t('Virtual Event')}</div>
          <img src={require('assets/images/projects/sdm/2_DeviceMockups' + french_version + '.png')} alt="project label" />
        </div>

        <div className="photo4 projectsectionhidden ">
          <div className="indicator">{t('Customized Virtual')}</div>
          <img src={require('assets/images/projects/sdm/booth.png')} alt="project label" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Augmented Reality Desc')}</div>
        </div>

        <div className="photo4 projectsectionhidden">
          <img src={require('assets/images/projects/sdm/4_ARPhotoCollage' + french_version + '.png')} alt="project label" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(VirtualBeauty);
