import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import gsap, { TimelineLite } from 'gsap';
import Parallax from 'parallax-js';
import $ from 'jquery';

import { offset, disableScroll, enableScroll } from 'utils/methods';

class Menu extends React.Component {

  state = {
    followingMouse: false,
    mouseX: 0,
    mouseY: 0,

    menuVisible: true
  }

  componentDidMount() {

    window.addEventListener('resize', () => { this.adjustIndicator(this.props.index) })
    this.adjustIndicator()
    window.hc.menu.toggleIcon = (index) => { this.adjustLogo(index) }


    this.parallax()
    this.createTimelines()
    this.listeners()
    this.adjustIndicator(window.hc.screenIndex)
    
    this.props.i18n.on('languageChanged', () => {
      setTimeout(()=>{
        this.adjustIndicator(this.props.index)
        this.adjustLogo()
      },100)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.index !== prevProps.index) {
      this.adjustIndicator(this.props.index)
    }
    this.adjustLogo()
  }

  adjustLogo(option) {

    const hcLogo = document.querySelector('#hc-logo')

    if (option)
      option === 0 ? hcLogo.classList.add('dark') : hcLogo.classList.remove('dark')

    else
      this.props.index === 1 ? hcLogo.classList.add('dark') : hcLogo.classList.remove('dark')

  }

  adjustIndicator(index) {

    const menuActiveItem = document.querySelectorAll('.menus .menu li')[index - 1]
    const menuIndicator = document.querySelector('.menus #menuIndicator')

    if (index === 0)
      gsap.to(menuIndicator, 0.4, { opacity: 0, width: 0 });

    else {
      if (!menuActiveItem) return

      const { left, width } = offset(menuActiveItem)

      gsap.to(menuIndicator, 0.2, {
        opacity: 1,
        width: `${width}px`,
        left: `${left}px`
      })
    }

  }

  toggleLanguage = () => {
    $('#root').css('opacity', 0)
    
    setTimeout(()=>{
      this.props.i18n.language === 'en' ? this.props.i18n.changeLanguage('fr') : this.props.i18n.changeLanguage('en');
      $('#root').css('opacity', 1);
    },1000)
  }

  toggleLanguageFull = () => {
    setTimeout(()=>{
      this.props.i18n.language === 'en' ? this.props.i18n.changeLanguage('fr') : this.props.i18n.changeLanguage('en');
    },500)
  }

  parallax() {
    const scene = document.querySelector('.menu-full #mapscene');
    new Parallax(scene);
  }

  listeners() {
    const menuFullListItems = $('.menu-full li')
    const activeClass = 'active'

    menuFullListItems.hover(
      (e) => {
        menuFullListItems.removeClass(activeClass)
        e.target.classList.add(activeClass)
      },
      (e) => {
        menuFullListItems.removeClass(activeClass)

        // Landing screen has index 0, but not part of menu items
        const activeItem = () => 
          window.hc.screenIndex <= 5 && window.hc.screenIndex > 0 ? 
            window.hc.screenIndex - 1 : 0;

        menuFullListItems[activeItem()].classList.add(activeClass)
      }
    )
  }

  createTimelines() {
    const menuFull = document.querySelector('.menu-full')
    const menuItems = document.querySelector('.menu-full ul')
    const menuMapCover = menuFull.querySelector('#map #cover')
    const menuLocation = menuFull.querySelector('#location')
    const menuBack = menuFull.querySelector('#back')

    this.timelines = {
      toggleMenu: new TimelineLite({
        paused: true
      })
    }

    this.timelines.toggleMenu
      .to(menuFull, 0.6, { left: 0 }, 0)
      .to(menuItems, 0.6, { opacity: 1, top: '50%' }, 1)
      .to(menuMapCover, 0.6, { width: 0 }, 0.6)
      .to(menuLocation, 0.4, { opacity: 1, transform: 'translateY(0)' }, 1)
      .to(menuBack, 0.4, { opacity: 0.25 }, 1.2)
  }

  render() {
    const { menuVisible } = this.state;
    const { index, t, i18n} = this.props;

    const reverseFullMenu = () => {
      this.timelines.toggleMenu.reverse()
      enableScroll()
    }

    return (
      <div className="menus">

        <Link to='/'>
          <div id="hc-logo">
            <img id="white" src={require('assets/images/logos/HC-white.png')} alt="white HC logo" />
            <img id="black" src={require('assets/images/logos/HC.png')} alt="black HC logo" />
          </div>
        </Link>

        <div id="menuitems">
          <div id="menuIndicator"></div>

          <div className="menu">
            <ul style={{ opacity: menuVisible ? 1 : 0 }}>
              <Link to='/about'><li>{t('ABOUT')}</li></Link>
              <Link to='/work'><li>{t('WORK')}</li></Link>
              <Link to='/humans'><li>{t('HUMANS')}</li></Link>
              <Link to='/careers'><li>{t('CAREERS')}</li></Link>
              <Link to='/contact'><li>{t('CONTACT')}</li></Link>
              <a href='https://merjj.com/' rel="noopener noreferrer" target="_blank"><li><img id="merge-logo" alt="Merge Logo" src={require('assets/images/logos/merjj_logo.png')}/></li></a>
              <button onClick={() => {
                this.toggleLanguage();
                document.activeElement.blur();
              }}><span tabindex="-1"><li className="language-toggle">{t('menu-language')}</li></span></button>
            </ul>
          </div>
        </div>

        <div className="burger" onClick={() => {
          this.timelines.toggleMenu.play()
          disableScroll()
        }}>
          <div className="icon"></div>
          <h4>{t('MENU')}</h4>
        </div>

        <div className={"menu-full" + (i18n.language === 'fr' ? ' french-menu-full' : '')}>
          <div id="content">
            <div id="back" onClick={() => { reverseFullMenu() }}>{t('back')}</div>

            <ul>
              <Link to='/about' onClick={() => { reverseFullMenu() }}><li className={index === 1 ? 'active' : ''}>{t('ABOUT')}</li></Link>
              <Link to='/work' onClick={() => { reverseFullMenu() }}><li className={index === 2 ? 'active' : ''}>{t('WORK')}</li></Link>
              <Link to='/humans' onClick={() => { reverseFullMenu() }}><li className={index === 4 ? 'active' : ''}>{t('HUMANS')}</li></Link>
              <Link to='/careers' onClick={() => { reverseFullMenu() }}><li className={index === 3 ? 'active' : ''}>{t('CAREERS')}</li></Link>
              <Link to='/contact' onClick={() => { reverseFullMenu() }}><li className={index === 5 ? 'active' : ''}>{t('CONTACT')}</li></Link>
              <a href='https://merjj.com/' rel="noopener noreferrer" target="_blank"><li><img id="merge-logo" alt="Merge Logo" src={require('assets/images/logos/merjj_logo.png')}/></li></a>
              <a onClick={()=> {
                reverseFullMenu();
                this.toggleLanguageFull();
                }}><li >{t('menu-language')}</li></a>
            </ul>

            <div id="location">
              <h4>1207 KING STREET WEST</h4>
              <h4>TORONTO, ON M6K 1G2</h4>
            </div>
          </div>
          <div id="map">
            <div id="cover"></div>
            <div id="mapscene">
              <img data-depth="0.25" src={require('assets/images/backgrounds/hc-map.png')} alt="static parallax map" />
            </div>
          </div>
        </div>

      </div>
    )
  }

}

export default withTranslation()(Menu);
