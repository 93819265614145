import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class SOGC extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;
    const french_version = i18n.language === 'fr' ? '-fr' : '';
    return (
      <div id="project-sogc" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <img id="logo1" src={require('assets/images/projects/sogc/1_SOGCHeader' + french_version + '.png')} alt="SOGC Header" /> 
        </div>

        <div className="services projectsectionhidden">
          <div className="indicator top_0">{t('Services')}</div>
          <div class="service">
            <h4>{t('Strategic Direction')}</h4>
            <h4>{t('Creative Direction')}</h4>
            <h4>{t('Graphic Design')}</h4>
            <h4>{t('Video Production')}</h4>
            <h4>{t('Social Media Management')}</h4>
            <h4>{t('Analytics')}</h4>
          </div>
        </div>

        <div className="description">
          <img src={require('assets/images/logos/clients/SOGC.png')} alt="SOGC Logo" />
          <p dangerouslySetInnerHTML={{__html: i18n.language === 'en' ? 
            `Education and awareness for public health topics is an important part of
            the mandate for the Society of Obstetricians and Gynaecologists of
            Canada (SOGC). <strong> HUMANCONTACT was retained to conceptualize,
            produce, and execute on two awareness campaigns — one focused
            on HPV and one focused on Contraception.</strong>
            <br/><br/> Each campaign involved social media campaigns, as well as a live
            broadcast for Canadians. Our focus was to deliver engaging creative
            concepts that would entice consumers to view the streams and visit the
            respective websites for additional resources and information.
            <br/><br/> <div class="last_sentence">All told, <strong> more than 3 million impressions were generated
            through the campaign.</strong></div>` 
            : 
            `L'éducation et la sensibilisation aux questions de santé publique sont au coeur
            du mandat de la Société des obstétriciens et gynécologues du Canada
            (SOGC). <strong> Les services de HUMANCONTACT ont été retenus
            pour conceptualiser, produire et exécuter deux campagnes de
            sensibilisation : l'une axée sur le VPH et la seconde sur la contraception. </strong>
            <br/><br/>Chaque campagne comprenait des campagnes sur les médias sociaux, ainsi
            qu'une diffusion en direct pour les Canadiens et Canadiennes. Notre objectif
            était de proposer des concepts créatifs attrayants qui inciteraient les
            consommateurs à visionner les diffusions et à visiter les sites Web respectifs
            pour obtenir des ressources et des renseignements supplémentaires.
            <br/><br/><div class="last_sentence"> Au total, <strong> plus de trois millions d'impressions ont été générées
            par la campagne.</strong> </div>`}}>
          </p>
        </div>

        <div className="photo4 projectsectionhidden">
          <div className="indicator">{t('Vibrant Colour Palette')}</div>
          <img src={require('assets/images/projects/sogc/2_ColourPalette' + french_version + '.png')} alt="project label" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Meme Parodies')}</div>
        </div>

        <div className="photo4 projectsectionhidden">
          <img src={require('assets/images/projects/sogc/3_Illustrations' + french_version + '.png')} alt="project label" />
        </div>

        <div className="photo4 projectsectionhidden">
          <div className="indicator top_2">{t('Digital Marketing — Social Media Posts')}</div> 
          <img src={require('assets/images/projects/sogc/4_DeviceMockups' + french_version + '.png')} alt="project label" />
        </div>

        <div className="photo4 projectsectionhidden">
         <div className="indicator top_3">{t('Digital Marketing — Social Media Posts')}</div>
          <img src={require('assets/images/projects/sogc/5_HPVSocialMedia' + french_version + '.png')} alt="project label" />
        </div>
        <div className="indicatorcontainer projectsectionhidden video_indicator">
          <div className="indicator">{t('Educational Video')}</div>
        </div>

        <div className="productvideo projectsectionhidden">
          
          <video width="100%" height="auto" controls={true} poster={require('assets/images/projects/sogc/6_INSERT-HPV-VIDEO-SECTION.png')}>
            <source src={require('assets/videos/sogc-hpv.mp4')} type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

        <div className="photo4 projectsectionhidden">
         <div className="indicator">{t('Facebook Live Virtual Events')}</div>
          <img src={require('assets/images/projects/sogc/7_LiveStreams' + french_version + '.png')} alt="project label" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(SOGC);
